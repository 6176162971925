@use 'assets/style/global';

@font-face {
  font-family: Outfit;
  src: url('assets/font/Outfit.ttf') format('truetype');
}

body {
  font-family: Outfit, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0!important;
  padding: 0!important;
}

h1 {
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

h2 {
  text-align: center;
  font-style: normal;
  line-height: normal;
  margin: 0;
}

p {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 5px 0;
}
