@use "../../../../assets/style/theming";

header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;

  .header-left {
    display: flex;
    flex-flow: row;
    align-items: center;

    nav {
      margin-left: 20px;
      height: 80%;
      display: flex;
      align-items: center;
      border: solid #fff;
      border-width: 0 0 0 2px;

      a {
        font-size: 20px;
        font-weight: normal;
        margin: 0 20px;

        &.active {
          color: var(--primary)
        }
      }
    }
  }
}
