#root {
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  background-color: black;
  background-image: url("/assets/images/svg/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.page {
  &.standard {
    width: 95%;
    max-width: 1000px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.icon {
  &.small {
    max-height: 15px;
  }

  &.medium {
    max-height: 20px;
  }

  &.big {
    max-height: 45px;
  }

  width: auto;
}

.navigate-button {
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
}

button {
  font-family: Outfit, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 16px;
  height: 40px;
  border: none;
  padding: 0 20px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: normal;
}
