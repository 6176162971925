
.for-the-stalker {
  display: flex;
  flex-flow: row;
  align-items: baseline;
}

.hero-main {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 20vh;

  .hero-top {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .hero-middle {
    padding: 40px 0;

    p {
      font-size: 20px;
    }
  }

  .hero-bottom {
    display: flex;
    flex-flow: row;
    margin-top: 20px;
    width: 95%;
    max-width: 300px;
    justify-content: space-between;

    button {
      width: 130px;
    }
  }
}

.for-the-stalker {
  p {
    margin: 0 10px;
  }
}
