.temporary-home-body {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
}

.social-medias-links {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  a {
    cursor: pointer;
  }

  svg{
    height: 2em;
    width: auto;
  }
}
